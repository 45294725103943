
    <template>
      <section class="content element-doc">
        <h2>Tree 树形控件</h2>
<p>用清晰的层级结构展示信息，可展开或折叠。</p>
<div class="warning">
<p>添加节点、删除节点等操作必须使用 ElTree 提供的方法进行。</p>
</div>
<h3>基础用法</h3>
<p>基础的树形结构展示。</p>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tree
  :data=&quot;data&quot;
  :defaultNodeKey=&quot;defaultNodeKey&quot;
  @node-click=&quot;handleNodeClick&quot;
&gt;&lt;/el-tree&gt;

&lt;script&gt;
  export default {
    data() {
      return {
        data: [
          {
            label: '一级 1',
            children: [
              {
                label: '二级 1-1',
                children: [
                  {
                    label: '三级 1-1-1'
                  }
                ]
              }
            ]
          },
          {
            label: '一级 2',
            children: [
              {
                label: '二级 2-1',
                children: [
                  {
                    label: '三级 2-1-1'
                  }
                ]
              },
              {
                label: '二级 2-2',
                children: [
                  {
                    label: '三级 2-2-1'
                  }
                ]
              }
            ]
          },
          {
            label: '一级 3',
            children: [
              {
                label: '二级 3-1',
                children: [
                  {
                    label: '三级 3-1-1'
                  }
                ]
              },
              {
                label: '二级 3-2',
                children: [
                  {
                    label: '三级 3-2-1'
                  }
                ]
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    },
    methods: {
      handleNodeClick(node) {
        console.log(node)
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>可选择</h3>
<p>适用于需要选择层级时使用。</p>
<demo-block>
        <div><p>本例还展示了动态加载节点数据的方法。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tree
  :defaultNodeKey=&quot;defaultNodeKey&quot;
  :async-load-fn=&quot;loadNode&quot;
  async
  show-checkbox
  @check-change=&quot;handleCheckChange&quot;
&gt;
&lt;/el-tree&gt;

&lt;script&gt;
  export default {
    data() {
      return {
        defaultNodeKey: {
          label: 'name',
          childNodes: 'zones'
        },
        count: 1
      }
    },
    methods: {
      handleCheckChange(node, e) {
        console.log(node, e)
      },
      handleNodeClick(node) {
        console.log(node)
      },
      loadNode(node, resolve) {
        if (node.level === 0) {
          return resolve([{ name: 'region1' }, { name: 'region2' }])
        }
        if (node.level &gt; 3) return resolve([])

        var hasChild
        if (node.data.raw.name === 'region1') {
          hasChild = true
        } else if (node.data.raw.name === 'region2') {
          hasChild = false
        } else {
          hasChild = Math.random() &gt; 0.5
        }

        setTimeout(() =&gt; {
          var data
          if (hasChild) {
            data = [
              {
                name: 'zone' + this.count++
              },
              {
                name: 'zone' + this.count++
              }
            ]
          } else {
            data = []
          }

          resolve(data)
        }, 500)
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>懒加载自定义叶子节点</h3>
<demo-block>
        <div><p>由于在点击节点时才进行该层数据的获取，默认情况下 Tree 无法预知某个节点是否为叶子节点，所以会为每个节点添加一个下拉按钮，如果节点没有下层数据，则点击后下拉按钮会消失。同时，你也可以提前告知 Tree 某个节点是否为叶子节点，从而避免在叶子节点前渲染下拉按钮。</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tree
  :defaultNodeKey=&quot;defaultNodeKey&quot;
  :async-load-fn=&quot;loadNode&quot;
  async
  show-checkbox
&gt;
&lt;/el-tree&gt;

&lt;script&gt;
  export default {
    data() {
      return {
        defaultNodeKey: {
          label: 'name',
          childNodes: 'zones',
          isLeaf: 'leaf'
        }
      }
    },
    methods: {
      loadNode(node, resolve) {
        if (node.level === 0) {
          return resolve([{ name: 'region' }])
        }
        if (node.level &gt; 1) return resolve([])

        setTimeout(() =&gt; {
          const data = [
            {
              name: 'leaf',
              leaf: true
            },
            {
              name: 'zone'
            }
          ]

          resolve(data)
        }, 500)
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>默认展开和默认选中</h3>
<p>可将 Tree 的某些节点设置为默认展开或默认选中</p>
<demo-block>
        <div><p>分别通过<code>expanded</code>和<code>checked</code>设置默认展开和默认选中的节点, 并且可以使用 vMdel 来同步当前树的展开节点状态和选中节点状态。需要注意的是，此时必须设置<code>defaultNodeKey.id</code>，其值为节点数据中的一个字段名，该字段在整棵树中是唯一的。</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tree
  :data=&quot;data&quot;
  show-checkbox
  v-model:expanded=&quot;expandedList&quot;
  v-model:checked=&quot;checkedList&quot;
  :defaultNodeKey=&quot;defaultNodeKey&quot;
&gt;
&lt;/el-tree&gt;

&lt;script&gt;
  export default {
    data() {
      return {
        expandedList: [4, 5],
        checkedList: [5],
        data: [
          {
            id: 1,
            label: '一级 1',
            children: [
              {
                id: 4,
                label: '二级 1-1',
                children: [
                  {
                    id: 9,
                    label: '三级 1-1-1'
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            label: '一级 2',
            children: [
              {
                id: 5,
                label: '二级 2-1'
              },
              {
                id: 6,
                label: '二级 2-2'
              }
            ]
          },
          {
            id: 3,
            label: '一级 3',
            children: [
              {
                id: 7,
                label: '二级 3-1'
              },
              {
                id: 8,
                label: '二级 3-2'
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>禁用状态</h3>
<p>可将 Tree 的某些节点设置为禁用状态</p>
<demo-block>
        <div><p>通过<code>disabled</code>设置禁用状态。</p>
</div>
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tree
  :data=&quot;data&quot;
  show-checkbox
  v-model:expanded=&quot;expandedList&quot;
  v-model:checked=&quot;checkedList&quot;
  :defaultNodeKey=&quot;defaultNodeKey&quot;
&gt;
&lt;/el-tree&gt;

&lt;script&gt;
  export default {
    data() {
      return {
        expandedList: [4, 5],
        checkedList: [5],
        data: [
          {
            id: 1,
            label: '一级 1',
            children: [
              {
                id: 4,
                label: '二级 1-1',
                children: [
                  {
                    id: 9,
                    label: '三级 1-1-1',
                    disabled: true
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            label: '一级 2',
            children: [
              {
                id: 5,
                label: '二级 2-1'
              },
              {
                id: 6,
                label: '二级 2-2'
              }
            ]
          },
          {
            id: 3,
            label: '一级 3',
            disabled: true,
            children: [
              {
                id: 7,
                label: '二级 3-1'
              },
              {
                id: 8,
                label: '二级 3-2'
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          isDisabled: 'disabled',
          label: 'label'
        }
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>树节点的选择</h3>
<demo-block>
        <div><p>本例展示如何获取和设置选中节点。获取和设置各有两种方式：通过 node 或通过 key。如果需要通过 key 来获取或设置，则必须设置<code>node-key</code>。</p>
</div>
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tree
  :data=&quot;data&quot;
  show-checkbox
  default-expand-all
  ref=&quot;tree&quot;
  highlight-current
  :defaultNodeKey=&quot;defaultNodeKey&quot;
&gt;
&lt;/el-tree&gt;
&lt;div class=&quot;buttons&quot;&gt;
  &lt;el-button @click=&quot;findNodes&quot;&gt;查找所有选中的元素（结果看控制台）&lt;/el-button&gt;
  &lt;el-button @click=&quot;findNode&quot;&gt;通过ID获取ID为10的节点&lt;/el-button&gt;
  &lt;el-button @click=&quot;setCheckedNodes&quot;&gt;设置并展开ID为10的节点&lt;/el-button&gt;
&lt;/div&gt;

&lt;script&gt;
  export default {
    methods: {
      findNodes() {
        console.log(this.$refs.tree.findMany((node) =&gt; node.isChecked))
      },
      findNode() {
        console.log(this.$refs.tree.findOne(10))
      },
      setCheckedNodes() {
        this.$refs.tree.findOne(10).setChecked(true)
        this.$refs.tree.findOne(10).expand(true)
      }
    },

    data() {
      return {
        data: [
          {
            id: 1,
            label: '一级 1',
            children: [
              {
                id: 4,
                label: '二级 1-1',
                children: [
                  {
                    id: 9,
                    label: '三级 1-1-1'
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            label: '一级 2',
            children: [
              {
                id: 5,
                label: '二级 2-1'
              },
              {
                id: 6,
                label: '二级 2-2'
              }
            ]
          },
          {
            id: 3,
            label: '一级 3',
            children: [
              {
                id: 7,
                label: '二级 3-1'
              },
              {
                id: 8,
                label: '二级 3-2'
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>自定义节点内容</h3>
<p>节点的内容支持自定义，可以在节点区添加按钮或图标等内容</p>
<demo-block>
        <div><p>可以通过两种方法进行树节点内容的自定义：<code>render-content</code>和 scoped slot。使用<code>render-content</code>指定渲染函数，该函数返回需要的节点区内容即可。渲染函数的用法请参考 Vue 文档。使用 scoped slot 会传入两个参数<code>node</code>和<code>data</code>，分别表示当前节点的 Node 对象和当前节点的数据。注意：由于 jsfiddle 不支持 JSX 语法，所以<code>render-content</code>示例在 jsfiddle 中无法运行。但是在实际的项目中，只要正确地配置了相关依赖，就可以正常运行。</p>
</div>
        <template #source><element-demo6 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div class=&quot;custom-tree-container&quot;&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;p&gt;使用 render-content&lt;/p&gt;
    &lt;el-tree
      :data=&quot;data1&quot;
      show-checkbox
      default-expand-all
      :expand-on-click-node=&quot;false&quot;
      :render-content=&quot;renderContent&quot;
    &gt;
    &lt;/el-tree&gt;
  &lt;/div&gt;
  &lt;div class=&quot;block&quot;&gt;
    &lt;p&gt;使用 scoped slot&lt;/p&gt;
    &lt;el-tree
      :data=&quot;data2&quot;
      show-checkbox
      default-expand-all
      :expand-on-click-node=&quot;false&quot;
    &gt;
      &lt;template v-slot=&quot;{ node, data }&quot;&gt;
        &lt;span class=&quot;custom-tree-node&quot;&gt;
          &lt;span&gt;{{ data.label }}&lt;/span&gt;
          &lt;span&gt;
            &lt;el-button type=&quot;text&quot; size=&quot;mini&quot; @click=&quot;append(node, data)&quot;&gt;
              Append
            &lt;/el-button&gt;
            &lt;el-button type=&quot;text&quot; size=&quot;mini&quot; @click=&quot;remove(node, data)&quot;&gt;
              Delete
            &lt;/el-button&gt;
          &lt;/span&gt;
        &lt;/span&gt;
      &lt;/template&gt;
    &lt;/el-tree&gt;
  &lt;/div&gt;
&lt;/div&gt;

&lt;script&gt;
  let id = 1000

  export default {
    data() {
      const data = [
        {
          id: 1,
          label: '一级 1',
          childNodes: [
            {
              id: 4,
              label: '二级 1-1',
              childNodes: [
                {
                  id: 9,
                  label: '三级 1-1-1'
                },
                {
                  id: 10,
                  label: '三级 1-1-2'
                }
              ]
            }
          ]
        },
        {
          id: 2,
          label: '一级 2',
          childNodes: [
            {
              id: 5,
              label: '二级 2-1'
            },
            {
              id: 6,
              label: '二级 2-2'
            }
          ]
        },
        {
          id: 3,
          label: '一级 3',
          childNodes: [
            {
              id: 7,
              label: '二级 3-1'
            },
            {
              id: 8,
              label: '二级 3-2'
            }
          ]
        }
      ]
      return {
        data1: JSON.parse(JSON.stringify(data)),
        data2: JSON.parse(JSON.stringify(data))
      }
    },

    methods: {
      append(node, data) {
        const newChild = { id: id++, label: 'testtest', childNodes: [] }
        node.append(newChild)
      },

      remove(node, data) {
        node.remove()
      },

      renderContent({ node, data }) {
        return (
          &lt;span class=&quot;custom-tree-node&quot;&gt;
            &lt;span&gt;{data.label}&lt;/span&gt;
            &lt;span&gt;
              &lt;el-button
                size=&quot;mini&quot;
                type=&quot;text&quot;
                onClick={() =&gt; this.append(node, data)}
              &gt;
                Append
              &lt;/el-button&gt;
              &lt;el-button
                size=&quot;mini&quot;
                type=&quot;text&quot;
                onClick={() =&gt; this.remove(node, data)}
              &gt;
                Delete
              &lt;/el-button&gt;
            &lt;/span&gt;
          &lt;/span&gt;
        )
      }
    }
  }
&lt;/script&gt;

&lt;style&gt;
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>节点过滤</h3>
<p>通过关键字过滤树节点</p>
<demo-block>
        <div><p>在需要对节点进行过滤时，调用 Tree 实例的<code>filter</code>方法，参数为关键字。需要注意的是，此时需要设置<code>filter-node-method</code>，值为过滤函数。</p>
</div>
        <template #source><element-demo7 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-input placeholder=&quot;输入关键字进行过滤&quot; v-model=&quot;filterText&quot;&gt; &lt;/el-input&gt;

&lt;el-tree
  class=&quot;filter-tree&quot;
  :data=&quot;data&quot;
  :defaultNodeKey=&quot;defaultNodeKey&quot;
  default-expand-all
  ref=&quot;tree&quot;
&gt;
&lt;/el-tree&gt;

&lt;script&gt;
  export default {
    watch: {
      filterText(val) {
        this.$refs.tree.filter((node) =&gt; {
          return this.filterNode(val, node)
        })
      }
    },

    methods: {
      filterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      }
    },

    data() {
      return {
        filterText: '',
        data: [
          {
            id: 1,
            label: '一级 1',
            children: [
              {
                id: 4,
                label: '二级 1-1',
                children: [
                  {
                    id: 9,
                    label: '三级 1-1-1'
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            label: '一级 2',
            children: [
              {
                id: 5,
                label: '二级 2-1'
              },
              {
                id: 6,
                label: '二级 2-2'
              }
            ]
          },
          {
            id: 3,
            label: '一级 3',
            children: [
              {
                id: 7,
                label: '二级 3-1'
              },
              {
                id: 8,
                label: '二级 3-2'
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>手风琴模式</h3>
<p>对于同一级的节点，每次只能展开一个</p>
<demo-block>
        
        <template #source><element-demo8 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tree
  :data=&quot;data&quot;
  :defaultNodeKey=&quot;defaultNodeKey&quot;
  accordion
  @node-click=&quot;handleNodeClick&quot;
&gt;
&lt;/el-tree&gt;

&lt;script&gt;
  export default {
    data() {
      return {
        data: [
          {
            label: '一级 1',
            children: [
              {
                label: '二级 1-1',
                children: [
                  {
                    label: '三级 1-1-1'
                  }
                ]
              }
            ]
          },
          {
            label: '一级 2',
            children: [
              {
                label: '二级 2-1',
                children: [
                  {
                    label: '三级 2-1-1'
                  }
                ]
              },
              {
                label: '二级 2-2',
                children: [
                  {
                    label: '三级 2-2-1'
                  }
                ]
              }
            ]
          },
          {
            label: '一级 3',
            children: [
              {
                label: '二级 3-1',
                children: [
                  {
                    label: '三级 3-1-1'
                  }
                ]
              },
              {
                label: '二级 3-2',
                children: [
                  {
                    label: '三级 3-2-1'
                  }
                ]
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    },
    methods: {
      handleNodeClick(node, data) {
        console.log(node, data)
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>可拖拽节点</h3>
<demo-block>
        <div><p>通过 draggable 属性可让节点变为可拖拽。</p>
</div>
        <template #source><element-demo9 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tree
  :data=&quot;data&quot;
  :defaultNodeKey=&quot;defaultNodeKey&quot;
  default-expand-all
  @node-drag-start=&quot;handleDragStart&quot;
  @node-drag-enter=&quot;handleDragEnter&quot;
  @node-drag-leave=&quot;handleDragLeave&quot;
  @node-drag-over=&quot;handleDragOver&quot;
  @node-drag-end=&quot;handleDragEnd&quot;
  @node-drop=&quot;handleDrop&quot;
  draggable
  :allow-drop=&quot;allowDrop&quot;
  :allow-drag=&quot;allowDrag&quot;
&gt;
&lt;/el-tree&gt;

&lt;script&gt;
  export default {
    data() {
      return {
        data: [
          {
            id: 1,
            label: '一级 1',
            children: [
              {
                id: 4,
                label: '二级 1-1',
                children: [
                  {
                    id: 9,
                    label: '三级 1-1-1'
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            label: '一级 2',
            children: [
              {
                id: 5,
                label: '二级 2-1'
              },
              {
                id: 6,
                label: '二级 2-2'
              }
            ]
          },
          {
            id: 3,
            label: '一级 3',
            children: [
              {
                id: 7,
                label: '二级 3-1'
              },
              {
                id: 8,
                label: '二级 3-2',
                children: [
                  {
                    id: 11,
                    label: '三级 3-2-1'
                  },
                  {
                    id: 12,
                    label: '三级 3-2-2'
                  },
                  {
                    id: 13,
                    label: '三级 3-2-3'
                  }
                ]
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    },
    methods: {
      handleDragStart(node, ev) {
        console.log('drag start', node)
      },
      handleDragEnter(draggingNode, dropNode, ev) {
        console.log('tree drag enter: ', dropNode)
      },
      handleDragLeave(draggingNode, dropNode, ev) {
        console.log('tree drag leave: ', dropNode)
      },
      handleDragOver(draggingNode, dropNode, ev) {
        console.log('tree drag over: ', dropNode)
      },
      handleDragEnd(draggingNode, dropNode, ev) {
        console.log('tree drag end: ', dropNode)
      },
      handleDrop(draggingNode, dropNode, dropType, ev) {
        console.log('tree drop: ', dropNode, dropType)
      },
      allowDrop(draggingNode, dropNode, type) {
        if (dropNode.data.raw.label === '二级 3-1') {
          return type !== 'inner'
        } else {
          return true
        }
      },
      allowDrag(draggingNode) {
        return draggingNode.data.raw.label.indexOf('三级 3-2-2') === -1
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Structure</h3>
<p>TreeNode 是整个 <code>ElTree</code> 组件内部的节点类，在调用 <code>ElTree</code> 提供的方法的时候优先为用户返回的是 TreeNode 节点。</p>
<pre><code class="hljs language-ts"><span class="hljs-keyword">interface</span> TreeNode {
  <span class="hljs-attr">id</span>: ID

  <span class="hljs-attr">label</span>: <span class="hljs-built_in">string</span>

  <span class="hljs-attr">parent</span>: TreeNode

  <span class="hljs-attr">childNodes</span>: TreeNode[]

  <span class="hljs-attr">isVisable</span>: <span class="hljs-built_in">boolean</span>

  <span class="hljs-attr">isChecked</span>: <span class="hljs-built_in">boolean</span>

  <span class="hljs-attr">isIndeterminate</span>: <span class="hljs-built_in">boolean</span>

  <span class="hljs-attr">isExpanded</span>: <span class="hljs-built_in">boolean</span>

  <span class="hljs-attr">isDisabled</span>: <span class="hljs-built_in">boolean</span>

  <span class="hljs-attr">isDraggable</span>: <span class="hljs-built_in">boolean</span>

  <span class="hljs-attr">isRendered</span>: <span class="hljs-built_in">boolean</span>

  <span class="hljs-attr">data</span>: { <span class="hljs-attr">raw</span>: RawNode } <span class="hljs-comment">// Additional data carried by the node</span>

  <span class="hljs-attr">isLeaf</span>: <span class="hljs-built_in">boolean</span>

  <span class="hljs-attr">isAsync</span>: <span class="hljs-built_in">boolean</span> <span class="hljs-comment">// Load child only at expand time</span>

  <span class="hljs-attr">asyncState</span>: AsyncState <span class="hljs-comment">// notload || loaded || loading</span>

  <span class="hljs-attr">asyncLoadFn</span>: <span class="hljs-function">(<span class="hljs-params">currentNode: TreeNode, resolveFn: ResolveFn</span>) =&gt;</span> <span class="hljs-built_in">void</span> <span class="hljs-comment">// (currentNode, resolveFn) async load child node</span>

  <span class="hljs-attr">append</span>: <span class="hljs-function">(<span class="hljs-params">node: TreeNode | RawNode</span>) =&gt;</span> <span class="hljs-built_in">void</span>

  <span class="hljs-attr">remove</span>: <span class="hljs-function">() =&gt;</span> <span class="hljs-built_in">void</span>

  <span class="hljs-attr">insert</span>: <span class="hljs-function">(<span class="hljs-params">index: <span class="hljs-built_in">number</span>, node: TreeNode | RawNode</span>) =&gt;</span> <span class="hljs-built_in">void</span>

  <span class="hljs-attr">removeChild</span>: <span class="hljs-function">(<span class="hljs-params">index: <span class="hljs-built_in">number</span></span>) =&gt;</span> <span class="hljs-built_in">void</span>

  <span class="hljs-attr">setChecked</span>: <span class="hljs-function">(<span class="hljs-params">value?: <span class="hljs-built_in">boolean</span>, strictly?: <span class="hljs-built_in">boolean</span></span>) =&gt;</span> <span class="hljs-built_in">void</span>

  <span class="hljs-attr">setChildChildren</span>: <span class="hljs-function">(<span class="hljs-params">value: <span class="hljs-built_in">boolean</span></span>) =&gt;</span> <span class="hljs-built_in">void</span>

  <span class="hljs-attr">upwardEach</span>: <span class="hljs-function">(<span class="hljs-params">callback: <span class="hljs-built_in">Function</span>, opt: { isSkipSelf: <span class="hljs-built_in">boolean</span> }</span>) =&gt;</span> <span class="hljs-built_in">void</span>

  <span class="hljs-attr">depthEach</span>: <span class="hljs-function">(<span class="hljs-params">
    upToDownCallBack: (
      node: TreeNode,
      parentNode: TreeNode,
      deep: <span class="hljs-built_in">number</span>
    ) =&gt; <span class="hljs-built_in">boolean</span>,
    downToUpCallBack?: (
      node: TreeNode,
      parentNode: TreeNode,
      deep: <span class="hljs-built_in">number</span>
    ) =&gt; <span class="hljs-built_in">boolean</span>
  </span>) =&gt;</span> <span class="hljs-built_in">void</span>

  <span class="hljs-attr">findOne</span>: <span class="hljs-function">(<span class="hljs-params">target: TreeNode | ID</span>) =&gt;</span> TreeNode

  <span class="hljs-attr">findMany</span>: <span class="hljs-function">(<span class="hljs-params">label: <span class="hljs-built_in">string</span></span>) =&gt;</span> TreeNode

  <span class="hljs-attr">findChildIndex</span>: <span class="hljs-function">(<span class="hljs-params">target: TreeNode</span>) =&gt;</span> <span class="hljs-built_in">number</span>

  <span class="hljs-attr">expand</span>: <span class="hljs-function">(<span class="hljs-params">value: <span class="hljs-built_in">boolean</span>, ...extraNodes: TreeNode[] | RawNode[]</span>) =&gt;</span> <span class="hljs-built_in">void</span>

  <span class="hljs-attr">setVsiable</span>: <span class="hljs-function">(<span class="hljs-params">value: <span class="hljs-built_in">boolean</span></span>) =&gt;</span> <span class="hljs-built_in">void</span>

  <span class="hljs-attr">move</span>: <span class="hljs-function">(<span class="hljs-params">target: TreeNode, relative: Relative</span>) =&gt;</span> <span class="hljs-built_in">boolean</span>

  <span class="hljs-attr">filter</span>: <span class="hljs-function">(<span class="hljs-params">
    callback: (node: TreeNode, parentNode: TreeNode, deep: <span class="hljs-built_in">number</span>) =&gt; <span class="hljs-built_in">boolean</span>
  </span>) =&gt;</span> <span class="hljs-built_in">boolean</span>
}
</code></pre>
<div class="warning">
<p>注意：TreeNode 是内部节点类，RawNode 是用户的节点类, 有些方法可以传 TreeNode 也可以传 RawNode, 他内部会自动识别 RawNode，并且转换为 TreeNode.</p>
</div>
<h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>data</td>
<td>展示数据</td>
<td>array</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>empty-text</td>
<td>内容为空的时候展示的文本</td>
<td>String</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>default-node-key</td>
<td>配置选项，具体看下表</td>
<td>DefaultNodeKey</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>render-after-expand</td>
<td>是否在第一次展开某个树节点后才渲染其子节点</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>async-load-fn</td>
<td>异步加载子树数据的方法，仅当 async 属性为 true 时生效</td>
<td>function(node:TreeNode, resolve:Function)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>render-content</td>
<td>树节点的内容区的渲染 Function</td>
<td>Function({ treeNode, rawNode })</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>highlight-current</td>
<td>是否高亮当前选中节点，默认值是 false。</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>default-expand-all</td>
<td>是否默认展开所有节点</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>expand-on-click-node</td>
<td>是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>check-on-click-node</td>
<td>是否在点击节点的时候选中节点，默认值为 false，即只有在点击复选框时才会选中节点。</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>expanded(VModel)</td>
<td>展开的节点的 key 的数组</td>
<td>array</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>show-checkbox</td>
<td>节点是否可被选择</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>check-strictly</td>
<td>在显示复选框的情况下，是否严格的遵循父子不互相关联的做法，默认为 false</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>checked(VModel)</td>
<td>勾选的节点的 key 的数组</td>
<td>array</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>current-node-key</td>
<td>当前选中的节点</td>
<td>string, number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>accordion</td>
<td>是否每次只打开一个同级树节点展开</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>indent</td>
<td>相邻级节点间的水平缩进，单位为像素</td>
<td>number</td>
<td>—</td>
<td>16</td>
</tr>
<tr>
<td>icon-class</td>
<td>自定义树节点的图标</td>
<td>string</td>
<td>-</td>
<td>-</td>
</tr>
<tr>
<td>async</td>
<td>是否异步加载子节点，需与 asyncLoadFn 方法结合使用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>draggable</td>
<td>是否开启拖拽节点功能</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>allow-drag</td>
<td>判断节点能否被拖拽</td>
<td>Function(node, e)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>allow-drop</td>
<td>拖拽时判定目标节点能否被放置。<code>type</code> 参数有三种情况：'top'、'inner' 和 'bottom'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后</td>
<td>Function(draggingNode, dropNode, type)</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>
<h3>DefaultNodeKey</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>id</td>
<td>每个节点用来作为唯一标识的属性，整棵树应该是唯一的</td>
<td>string</td>
<td>—</td>
<td>id</td>
</tr>
<tr>
<td>label</td>
<td>指定节点标签为节点对象的某个属性值</td>
<td>string</td>
<td>—</td>
<td>label</td>
</tr>
<tr>
<td>childNodes</td>
<td>指定子树为节点对象的某个属性值</td>
<td>string</td>
<td>—</td>
<td>children</td>
</tr>
<tr>
<td>isDisabled</td>
<td>指定节点选择框是否禁用为节点对象的某个属性值</td>
<td>string</td>
<td>—</td>
<td>isDisabled</td>
</tr>
<tr>
<td>isAsync</td>
<td>指定节点是否为异步节点</td>
<td>string</td>
<td>—</td>
<td>isAsync</td>
</tr>
<tr>
<td>isChecked</td>
<td>指定节点是否为选中状态</td>
<td>string</td>
<td>—</td>
<td>isChecked</td>
</tr>
<tr>
<td>isVisable</td>
<td>指定节点是否可显示</td>
<td>string</td>
<td>—</td>
<td>isVisable</td>
</tr>
<tr>
<td>isExpanded</td>
<td>指定节点是否为展开状态</td>
<td>string</td>
<td>—</td>
<td>isExpanded</td>
</tr>
</tbody>
</table>
<h3>Methods</h3>
<p><code>Tree</code> 内部使用了 <code>TreeNode</code> 类型的对象来包装用户传入（<code>RawNode</code>）的数据，用来保存目前节点的状态。</p>
<p>通过访问 ref 来获取 tree 对象，并且 Tree 组件暴露了<code>tree</code>变量与<code>root</code>变量</p>
<p>以下的方法都是直接从上面的两个对象导出，目的是为了调用方便</p>
<p><code>TreeNode</code>与<code>Tree</code> 方法：</p>
<table>
<thead>
<tr>
<th>方法名</th>
<th>参数</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>append(node)</td>
<td>node:TreeNode|RawNode</td>
<td>为当前节点添加子节点</td>
</tr>
<tr>
<td>remove()</td>
<td>NULL</td>
<td>删除当前节点</td>
</tr>
<tr>
<td>insert(index, node)</td>
<td>index:Number, node:TreeNode|RawNode</td>
<td>为当前节点的插入一个子元素，到 index 位置</td>
</tr>
<tr>
<td>removeChild(index)</td>
<td>index:Number</td>
<td>移除 index 位置的子元素</td>
</tr>
<tr>
<td>setChecked(value, strictly)</td>
<td>value:Boolean = !value, stricly:Boolean = false</td>
<td>设置元素为选中状态，strictly 表示是否只选中当前节点</td>
</tr>
<tr>
<td>setChildChecked(value)</td>
<td>value:Boolean</td>
<td>设置子节点为 value</td>
</tr>
<tr>
<td>upwardEach(callback, opts)</td>
<td>callback(node:TreeNode)=&gt;Boolean, opts.isSkipSelf:Boolean = true</td>
<td>从当前节点向上遍历树，opts.isSkipSelf 是否跳过当前节点，回调函数的返回 true 的话会停止遍历</td>
</tr>
<tr>
<td>depthEach(upToDownCallBack, downToUpCallBack)</td>
<td>upToDownCallBack(node:TreeNode, parentNode:TreeNode, deep: number)=&gt;Boolean，downToUpCallBack(node:TreeNode, parentNode:TreeNode, deep: number)=&gt;Boolean</td>
<td>从 当前节点向下遍历，回调函数的返回 true 的话会停止遍历</td>
</tr>
<tr>
<td>findOne(target) =&gt; TreeNode</td>
<td>target:TreeNode|ID</td>
<td>查找当前当前子树的目标节点，可以通过 id 或者节点查询</td>
</tr>
<tr>
<td>findMany(label) =&gt; TreeNode[]</td>
<td>label:String</td>
<td>通过 label 查找节点</td>
</tr>
<tr>
<td>findChildIndex(target) =&gt; Number</td>
<td>target:TreeNode</td>
<td>只查找子节点</td>
</tr>
<tr>
<td>expand(value, ...extraNodes)</td>
<td>value:Boolean = !value, extraNodes: TreeNodo|RawNode = []</td>
<td>展开该节点，在展开的时候可以添加节点</td>
</tr>
<tr>
<td>setVsiable(value)</td>
<td>value:Boolean = !value</td>
<td>设置当前节点是否可见</td>
</tr>
<tr>
<td>move(target, relative)</td>
<td>target:TreeNode, relative:String</td>
<td>移动当前节点到目标节点的相对位置（上,内，下），relative <em>top, bottom, inner</em></td>
</tr>
<tr>
<td>filter(callback)</td>
<td>callback(node, parentNode, deep) =&gt; Boolean</td>
<td>筛选节点，callback 返回 true 表示显示，返回 false 表示隐藏</td>
</tr>
</tbody>
</table>
<p><code>Tree</code>方法：</p>
<table>
<thead>
<tr>
<th>方法名</th>
<th>参数</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>initRoot()</td>
<td>NULL</td>
<td>重新构建树</td>
</tr>
<tr>
<td>getParentRawNode(rawNode) =&gt; RawNode</td>
<td>rawNode:RawNode</td>
<td>获取当前原始节点的父元素</td>
</tr>
<tr>
<td>showAll()</td>
<td>NULL</td>
<td>显示所有节点</td>
</tr>
<tr>
<td>checkedAll()</td>
<td>NULL</td>
<td>选中所有节点</td>
</tr>
<tr>
<td>expandAll()</td>
<td>NULL</td>
<td>展开所有节点</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>node-click</td>
<td>节点被点击时的回调</td>
<td>共两个参数，依次为：点击的节点，event</td>
</tr>
<tr>
<td>node-contextmenu</td>
<td>当某一节点被鼠标右键点击时会触发该事件</td>
<td>共两个参数，依次为：当前节点，event。</td>
</tr>
<tr>
<td>check-change</td>
<td>节点选中状态发生变化时的回调</td>
<td>共两个参数，依次为：当前节点，event（选中状态）</td>
</tr>
<tr>
<td>check</td>
<td>当复选框被点击的时候触发</td>
<td>共三个参数，依次为 当前节点, 选中状态，event</td>
</tr>
<tr>
<td>current-change</td>
<td>当前选中节点变化时触发的事件</td>
<td>共两个参数，依次为：当前节点，event</td>
</tr>
<tr>
<td>node-expand</td>
<td>节点被展开时触发的事件</td>
<td>共两个参数，依次为：当前节点，event</td>
</tr>
<tr>
<td>node-collapse</td>
<td>节点被关闭时触发的事件</td>
<td>共两个参数，依次为：当前节点，event</td>
</tr>
<tr>
<td>node-drag-start</td>
<td>节点开始拖拽时触发的事件</td>
<td>共两个参数，依次为：被拖拽节点对应的 Node、event</td>
</tr>
<tr>
<td>node-drag-enter</td>
<td>拖拽进入其他节点时触发的事件</td>
<td>共三个参数，依次为：被拖拽节点对应的 Node、所进入节点对应的 Node、event</td>
</tr>
<tr>
<td>node-drag-leave</td>
<td>拖拽离开某个节点时触发的事件</td>
<td>共三个参数，依次为：被拖拽节点对应的 Node、所离开节点对应的 Node、event</td>
</tr>
<tr>
<td>node-drag-over</td>
<td>在拖拽节点时触发的事件（类似浏览器的 mouseover 事件）</td>
<td>共三个参数，依次为：被拖拽节点对应的 Node、当前进入节点对应的 Node、event</td>
</tr>
<tr>
<td>node-drag-end</td>
<td>拖拽结束时（可能未成功）触发的事件</td>
<td>共三个参数，依次为：被拖拽节点对应的 Node、结束拖拽时最后进入的节点（可能为空）、event</td>
</tr>
<tr>
<td>node-drop</td>
<td>拖拽成功完成时触发的事件</td>
<td>共四个参数，依次为：被拖拽节点对应的 Node、结束拖拽时最后进入的节点、被拖拽节点的放置位置（top、bottom、inner）、event</td>
</tr>
</tbody>
</table>
<h3>Scoped Slot</h3>
<table>
<thead>
<tr>
<th>name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>—</td>
<td>自定义树节点的内容，参数为 { treeNode, rawNode }</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tree = _resolveComponent("el-tree")

  return (_openBlock(), _createBlock(_component_el_tree, {
    data: _ctx.data,
    defaultNodeKey: _ctx.defaultNodeKey,
    onNodeClick: _ctx.handleNodeClick
  }, null, 8, ["data", "defaultNodeKey", "onNodeClick"]))
}
  
    const democomponentExport = {
    data() {
      return {
        data: [
          {
            label: '一级 1',
            children: [
              {
                label: '二级 1-1',
                children: [
                  {
                    label: '三级 1-1-1'
                  }
                ]
              }
            ]
          },
          {
            label: '一级 2',
            children: [
              {
                label: '二级 2-1',
                children: [
                  {
                    label: '三级 2-1-1'
                  }
                ]
              },
              {
                label: '二级 2-2',
                children: [
                  {
                    label: '三级 2-2-1'
                  }
                ]
              }
            ]
          },
          {
            label: '一级 3',
            children: [
              {
                label: '二级 3-1',
                children: [
                  {
                    label: '三级 3-1-1'
                  }
                ]
              },
              {
                label: '二级 3-2',
                children: [
                  {
                    label: '三级 3-2-1'
                  }
                ]
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    },
    methods: {
      handleNodeClick(node) {
        console.log(node)
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tree = _resolveComponent("el-tree")

  return (_openBlock(), _createBlock(_component_el_tree, {
    defaultNodeKey: _ctx.defaultNodeKey,
    "async-load-fn": _ctx.loadNode,
    async: "",
    "show-checkbox": "",
    onCheckChange: _ctx.handleCheckChange
  }, null, 8, ["defaultNodeKey", "async-load-fn", "onCheckChange"]))
}
  
    const democomponentExport = {
    data() {
      return {
        defaultNodeKey: {
          label: 'name',
          childNodes: 'zones'
        },
        count: 1
      }
    },
    methods: {
      handleCheckChange(node, e) {
        console.log(node, e)
      },
      handleNodeClick(node) {
        console.log(node)
      },
      loadNode(node, resolve) {
        if (node.level === 0) {
          return resolve([{ name: 'region1' }, { name: 'region2' }])
        }
        if (node.level > 3) return resolve([])

        var hasChild
        if (node.data.raw.name === 'region1') {
          hasChild = true
        } else if (node.data.raw.name === 'region2') {
          hasChild = false
        } else {
          hasChild = Math.random() > 0.5
        }

        setTimeout(() => {
          var data
          if (hasChild) {
            data = [
              {
                name: 'zone' + this.count++
              },
              {
                name: 'zone' + this.count++
              }
            ]
          } else {
            data = []
          }

          resolve(data)
        }, 500)
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tree = _resolveComponent("el-tree")

  return (_openBlock(), _createBlock(_component_el_tree, {
    defaultNodeKey: _ctx.defaultNodeKey,
    "async-load-fn": _ctx.loadNode,
    async: "",
    "show-checkbox": ""
  }, null, 8, ["defaultNodeKey", "async-load-fn"]))
}
  
    const democomponentExport = {
    data() {
      return {
        defaultNodeKey: {
          label: 'name',
          childNodes: 'zones',
          isLeaf: 'leaf'
        }
      }
    },
    methods: {
      loadNode(node, resolve) {
        if (node.level === 0) {
          return resolve([{ name: 'region' }])
        }
        if (node.level > 1) return resolve([])

        setTimeout(() => {
          const data = [
            {
              name: 'leaf',
              leaf: true
            },
            {
              name: 'zone'
            }
          ]

          resolve(data)
        }, 500)
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tree = _resolveComponent("el-tree")

  return (_openBlock(), _createBlock(_component_el_tree, {
    data: _ctx.data,
    "show-checkbox": "",
    expanded: _ctx.expandedList,
    "onUpdate:expanded": $event => (_ctx.expandedList = $event),
    checked: _ctx.checkedList,
    "onUpdate:checked": $event => (_ctx.checkedList = $event),
    defaultNodeKey: _ctx.defaultNodeKey
  }, null, 8, ["data", "expanded", "onUpdate:expanded", "checked", "onUpdate:checked", "defaultNodeKey"]))
}
  
    const democomponentExport = {
    data() {
      return {
        expandedList: [4, 5],
        checkedList: [5],
        data: [
          {
            id: 1,
            label: '一级 1',
            children: [
              {
                id: 4,
                label: '二级 1-1',
                children: [
                  {
                    id: 9,
                    label: '三级 1-1-1'
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            label: '一级 2',
            children: [
              {
                id: 5,
                label: '二级 2-1'
              },
              {
                id: 6,
                label: '二级 2-2'
              }
            ]
          },
          {
            id: 3,
            label: '一级 3',
            children: [
              {
                id: 7,
                label: '二级 3-1'
              },
              {
                id: 8,
                label: '二级 3-2'
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tree = _resolveComponent("el-tree")

  return (_openBlock(), _createBlock(_component_el_tree, {
    data: _ctx.data,
    "show-checkbox": "",
    expanded: _ctx.expandedList,
    "onUpdate:expanded": $event => (_ctx.expandedList = $event),
    checked: _ctx.checkedList,
    "onUpdate:checked": $event => (_ctx.checkedList = $event),
    defaultNodeKey: _ctx.defaultNodeKey
  }, null, 8, ["data", "expanded", "onUpdate:expanded", "checked", "onUpdate:checked", "defaultNodeKey"]))
}
  
    const democomponentExport = {
    data() {
      return {
        expandedList: [4, 5],
        checkedList: [5],
        data: [
          {
            id: 1,
            label: '一级 1',
            children: [
              {
                id: 4,
                label: '二级 1-1',
                children: [
                  {
                    id: 9,
                    label: '三级 1-1-1',
                    disabled: true
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            label: '一级 2',
            children: [
              {
                id: 5,
                label: '二级 2-1'
              },
              {
                id: 6,
                label: '二级 2-2'
              }
            ]
          },
          {
            id: 3,
            label: '一级 3',
            disabled: true,
            children: [
              {
                id: 7,
                label: '二级 3-1'
              },
              {
                id: 8,
                label: '二级 3-2'
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          isDisabled: 'disabled',
          label: 'label'
        }
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, createTextVNode: _createTextVNode, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tree = _resolveComponent("el-tree")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_tree, {
      data: _ctx.data,
      "show-checkbox": "",
      "default-expand-all": "",
      ref: "tree",
      "highlight-current": "",
      defaultNodeKey: _ctx.defaultNodeKey
    }, null, 8, ["data", "defaultNodeKey"]),
    _createVNode("div", { class: "buttons" }, [
      _createVNode(_component_el_button, { onClick: _ctx.findNodes }, {
        default: _withCtx(() => [
          _createTextVNode("查找所有选中的元素（结果看控制台）")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_el_button, { onClick: _ctx.findNode }, {
        default: _withCtx(() => [
          _createTextVNode("通过ID获取ID为10的节点")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_el_button, { onClick: _ctx.setCheckedNodes }, {
        default: _withCtx(() => [
          _createTextVNode("设置并展开ID为10的节点")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ], 64))
}
  
    const democomponentExport = {
    methods: {
      findNodes() {
        console.log(this.$refs.tree.findMany((node) => node.isChecked))
      },
      findNode() {
        console.log(this.$refs.tree.findOne(10))
      },
      setCheckedNodes() {
        this.$refs.tree.findOne(10).setChecked(true)
        this.$refs.tree.findOne(10).expand(true)
      }
    },

    data() {
      return {
        data: [
          {
            id: 1,
            label: '一级 1',
            children: [
              {
                id: 4,
                label: '二级 1-1',
                children: [
                  {
                    id: 9,
                    label: '三级 1-1-1'
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            label: '一级 2',
            children: [
              {
                id: 5,
                label: '二级 2-1'
              },
              {
                id: 6,
                label: '二级 2-2'
              }
            ]
          },
          {
            id: 3,
            label: '一级 3',
            children: [
              {
                id: 7,
                label: '二级 3-1'
              },
              {
                id: 8,
                label: '二级 3-2'
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo6": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, toDisplayString: _toDisplayString, createTextVNode: _createTextVNode, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tree = _resolveComponent("el-tree")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock("div", { class: "custom-tree-container" }, [
    _createVNode("div", { class: "block" }, [
      _createVNode("p", null, "使用 render-content"),
      _createVNode(_component_el_tree, {
        data: _ctx.data1,
        "show-checkbox": "",
        "default-expand-all": "",
        "expand-on-click-node": false,
        "render-content": _ctx.renderContent
      }, null, 8, ["data", "render-content"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("p", null, "使用 scoped slot"),
      _createVNode(_component_el_tree, {
        data: _ctx.data2,
        "show-checkbox": "",
        "default-expand-all": "",
        "expand-on-click-node": false
      }, {
        default: _withCtx(({ node, data }) => [
          _createVNode("span", { class: "custom-tree-node" }, [
            _createVNode("span", null, _toDisplayString(data.label), 1),
            _createVNode("span", null, [
              _createVNode(_component_el_button, {
                type: "text",
                size: "mini",
                onClick: $event => (_ctx.append(node, data))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Append ")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_el_button, {
                type: "text",
                size: "mini",
                onClick: $event => (_ctx.remove(node, data))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Delete ")
                ]),
                _: 2
              }, 1032, ["onClick"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["data"])
    ])
  ]))
}
  
    let id = 1000

  const democomponentExport = {
    data() {
      const data = [
        {
          id: 1,
          label: '一级 1',
          childNodes: [
            {
              id: 4,
              label: '二级 1-1',
              childNodes: [
                {
                  id: 9,
                  label: '三级 1-1-1'
                },
                {
                  id: 10,
                  label: '三级 1-1-2'
                }
              ]
            }
          ]
        },
        {
          id: 2,
          label: '一级 2',
          childNodes: [
            {
              id: 5,
              label: '二级 2-1'
            },
            {
              id: 6,
              label: '二级 2-2'
            }
          ]
        },
        {
          id: 3,
          label: '一级 3',
          childNodes: [
            {
              id: 7,
              label: '二级 3-1'
            },
            {
              id: 8,
              label: '二级 3-2'
            }
          ]
        }
      ]
      return {
        data1: JSON.parse(JSON.stringify(data)),
        data2: JSON.parse(JSON.stringify(data))
      }
    },

    methods: {
      append(node, data) {
        const newChild = { id: id++, label: 'testtest', childNodes: [] }
        node.append(newChild)
      },

      remove(node, data) {
        node.remove()
      },

      renderContent({ node, data }) {
        return (
          <span class="custom-tree-node">
            <span>{data.label}</span>
            <span>
              <el-button
                size="mini"
                type="text"
                onClick={() => this.append(node, data)}
              >
                Append
              </el-button>
              <el-button
                size="mini"
                type="text"
                onClick={() => this.remove(node, data)}
              >
                Delete
              </el-button>
            </span>
          </span>
        )
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo7": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_tree = _resolveComponent("el-tree")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_input, {
      placeholder: "输入关键字进行过滤",
      modelValue: _ctx.filterText,
      "onUpdate:modelValue": $event => (_ctx.filterText = $event)
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_tree, {
      class: "filter-tree",
      data: _ctx.data,
      defaultNodeKey: _ctx.defaultNodeKey,
      "default-expand-all": "",
      ref: "tree"
    }, null, 8, ["data", "defaultNodeKey"])
  ], 64))
}
  
    const democomponentExport = {
    watch: {
      filterText(val) {
        this.$refs.tree.filter((node) => {
          return this.filterNode(val, node)
        })
      }
    },

    methods: {
      filterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      }
    },

    data() {
      return {
        filterText: '',
        data: [
          {
            id: 1,
            label: '一级 1',
            children: [
              {
                id: 4,
                label: '二级 1-1',
                children: [
                  {
                    id: 9,
                    label: '三级 1-1-1'
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            label: '一级 2',
            children: [
              {
                id: 5,
                label: '二级 2-1'
              },
              {
                id: 6,
                label: '二级 2-2'
              }
            ]
          },
          {
            id: 3,
            label: '一级 3',
            children: [
              {
                id: 7,
                label: '二级 3-1'
              },
              {
                id: 8,
                label: '二级 3-2'
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo8": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tree = _resolveComponent("el-tree")

  return (_openBlock(), _createBlock(_component_el_tree, {
    data: _ctx.data,
    defaultNodeKey: _ctx.defaultNodeKey,
    accordion: "",
    onNodeClick: _ctx.handleNodeClick
  }, null, 8, ["data", "defaultNodeKey", "onNodeClick"]))
}
  
    const democomponentExport = {
    data() {
      return {
        data: [
          {
            label: '一级 1',
            children: [
              {
                label: '二级 1-1',
                children: [
                  {
                    label: '三级 1-1-1'
                  }
                ]
              }
            ]
          },
          {
            label: '一级 2',
            children: [
              {
                label: '二级 2-1',
                children: [
                  {
                    label: '三级 2-1-1'
                  }
                ]
              },
              {
                label: '二级 2-2',
                children: [
                  {
                    label: '三级 2-2-1'
                  }
                ]
              }
            ]
          },
          {
            label: '一级 3',
            children: [
              {
                label: '二级 3-1',
                children: [
                  {
                    label: '三级 3-1-1'
                  }
                ]
              },
              {
                label: '二级 3-2',
                children: [
                  {
                    label: '三级 3-2-1'
                  }
                ]
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    },
    methods: {
      handleNodeClick(node, data) {
        console.log(node, data)
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo9": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_tree = _resolveComponent("el-tree")

  return (_openBlock(), _createBlock(_component_el_tree, {
    data: _ctx.data,
    defaultNodeKey: _ctx.defaultNodeKey,
    "default-expand-all": "",
    onNodeDragStart: _ctx.handleDragStart,
    onNodeDragEnter: _ctx.handleDragEnter,
    onNodeDragLeave: _ctx.handleDragLeave,
    onNodeDragOver: _ctx.handleDragOver,
    onNodeDragEnd: _ctx.handleDragEnd,
    onNodeDrop: _ctx.handleDrop,
    draggable: "",
    "allow-drop": _ctx.allowDrop,
    "allow-drag": _ctx.allowDrag
  }, null, 8, ["data", "defaultNodeKey", "onNodeDragStart", "onNodeDragEnter", "onNodeDragLeave", "onNodeDragOver", "onNodeDragEnd", "onNodeDrop", "allow-drop", "allow-drag"]))
}
  
    const democomponentExport = {
    data() {
      return {
        data: [
          {
            id: 1,
            label: '一级 1',
            children: [
              {
                id: 4,
                label: '二级 1-1',
                children: [
                  {
                    id: 9,
                    label: '三级 1-1-1'
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2'
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            label: '一级 2',
            children: [
              {
                id: 5,
                label: '二级 2-1'
              },
              {
                id: 6,
                label: '二级 2-2'
              }
            ]
          },
          {
            id: 3,
            label: '一级 3',
            children: [
              {
                id: 7,
                label: '二级 3-1'
              },
              {
                id: 8,
                label: '二级 3-2',
                children: [
                  {
                    id: 11,
                    label: '三级 3-2-1'
                  },
                  {
                    id: 12,
                    label: '三级 3-2-2'
                  },
                  {
                    id: 13,
                    label: '三级 3-2-3'
                  }
                ]
              }
            ]
          }
        ],
        defaultNodeKey: {
          childNodes: 'children',
          label: 'label'
        }
      }
    },
    methods: {
      handleDragStart(node, ev) {
        console.log('drag start', node)
      },
      handleDragEnter(draggingNode, dropNode, ev) {
        console.log('tree drag enter: ', dropNode)
      },
      handleDragLeave(draggingNode, dropNode, ev) {
        console.log('tree drag leave: ', dropNode)
      },
      handleDragOver(draggingNode, dropNode, ev) {
        console.log('tree drag over: ', dropNode)
      },
      handleDragEnd(draggingNode, dropNode, ev) {
        console.log('tree drag end: ', dropNode)
      },
      handleDrop(draggingNode, dropNode, dropType, ev) {
        console.log('tree drop: ', dropNode, dropType)
      },
      allowDrop(draggingNode, dropNode, type) {
        if (dropNode.data.raw.label === '二级 3-1') {
          return type !== 'inner'
        } else {
          return true
        }
      },
      allowDrag(draggingNode) {
        return draggingNode.data.raw.label.indexOf('三级 3-2-2') === -1
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  